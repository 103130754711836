import { RESOURCE_CONFIG } from '@/utils/global';

/**
 * 判断当前订单组是否有 授权用户数，判断是否是买断订单
 */
export function isBuyCompletely(dataList: any) {
    const grantLen = dataList
        .filter((item: any) => {
            return item.isBuyout && item.goodsId === RESOURCE_CONFIG.GRANT_GOODSID;
        }).length;
    return grantLen > 0 ? true : false;
}

/**
 * 订单列表中，相关买断，屏蔽免费云服务器和云用户数，应用和授权用户数上下行数据组装
 */
export function assembleOrderItemBuyout(dataList: any, sortFlag = false) {
    let detailListCopy: any = [];
    dataList = dataList.filter((el: any) => {
        // 服务器 和 云用户数隐藏，如果服务器价格大于0 则显示
        // goodsType = 1 是云服务器
        // goodsType = 100 是云用户数
        return (el.goodsId !== RESOURCE_CONFIG.SERVER_GOODSID
            || (el.goodsId === RESOURCE_CONFIG.SERVER_GOODSID && el.goodsMoney > 0))
            && (el.goodsId !== RESOURCE_CONFIG.USERNUMBER_GOODSID
            || (el.goodsId === RESOURCE_CONFIG.USERNUMBER_GOODSID && el.goodsMoney > 0));
    });
    if (sortFlag) {
        dataList.forEach((el: any) => {
            if (el.goodsType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
                detailListCopy.unshift(el);
            } else if (el.goodsType === 11 && el.isBuyout) {
                const isHasApp = detailListCopy
                    .some((item: any) => item.goodsType === RESOURCE_CONFIG.APP_GOODSPRICETYPE);
                if (isHasApp) {
                    detailListCopy.splice(1, 0, el);
                } else {
                    detailListCopy.unshift(el);
                }
            } else {
                detailListCopy.push(el);
            }
        });
    } else {
        detailListCopy = [].concat(dataList);
    }
    return detailListCopy;
}

/**
 * 订单确认页中，相关买断，屏蔽免费云服务器和云用户数，应用和授权用户数上下行数据组装
 */
export function assembleOrderConfirmBuyout(dataList: any, filterFlag = true) {
    const detailListCopy: any = [];
    if (filterFlag) {
        dataList = dataList.filter((el: any) => {
            // 服务器 和 云用户数隐藏，如果服务器价格大于0 则显示
            return (el.goodsId !== RESOURCE_CONFIG.SERVER_GOODSID
                || (el.goodsId === RESOURCE_CONFIG.SERVER_GOODSID && el.orderDetailMoney > 0))
                && (el.goodsId !== RESOURCE_CONFIG.USERNUMBER_GOODSID
                || (el.goodsId === RESOURCE_CONFIG.USERNUMBER_GOODSID && el.orderDetailMoney > 0));
        });
    }
    dataList.forEach((el: any) => {
        if (el.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE) {
            detailListCopy.unshift(el);
        } else if (el.goodsId === RESOURCE_CONFIG.GRANT_GOODSID) {
            const isHasApp = detailListCopy
                .some((item: any) => item.goodsPriceType === RESOURCE_CONFIG.APP_GOODSPRICETYPE);
            if (isHasApp) {
                detailListCopy.splice(1, 0, el);
            } else {
                detailListCopy.unshift(el);
            }
        } else {
            detailListCopy.push(el);
        }
    });
    return detailListCopy;
}
